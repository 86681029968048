/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, PageProps, Link, graphql } from "gatsby"

import Header from "./header"
import Footer from './footer'
import "./layout.css"

type LayoutProps = {
  data: {
    site: {
      buildTime: string
      siteMetadata: {
        title: string;
      }
    }
  },
  readonly children?: React.ReactNode | readonly React.ReactNode[]
}

const Layout: React.FC<LayoutProps> = ({ data, children }) => {

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1200,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>

        <Footer />
      </div>
    </>
  )
}

export default Layout
